@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
*:focus {
  outline: 0;
}
html,
body,
#root {
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  background-color: #ffffff;
}
body,
input,
textarea,
button {
  font: 14px 'Hind', sans-serif;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
button {
  cursor: pointer;
}
.grid {
  width: 1200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
}
.icon-wrapper {
  color: #7624e8;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.page-title {
  font-size: 34px;
  margin-top: 48px;
  margin-bottom: 24px;
  font-family: 'Rubik';
}

.MuiMenuItem-root {
  font-family: 'Hind', sans-serif !important;
  font-size: 14px !important;
}

